.custom .video-js {
  width: 100%;
  height: 100%;
}

.custom .vjs-theme-fantasy {
  --vjs-theme-fantasy--primary: var(--chakra-colors-accentone-base);
  --vjs-theme-fantasy--secondary: #fff;
}

.custom .vjs-poster {
  background-size: cover;
  filter: grayscale(40%);
}

.custom .vjs-theme-fantasy:hover .vjs-big-play-button {
  transform: scale(1.2);
}

.custom .video-js.vjs-theme-fantasy .vjs-big-play-button {
  margin-top: 0;
  margin-left: 0;
  width: 100%;
  height: 100%;
  border: none;
  display: flex;
  top: 0;
  left: 0;
  border-radius: 0;
  color: white;
  transition: all 0.3s ease;
}

.custom .video-js .vjs-big-play-button .vjs-icon-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin: auto;
}

.custom .video-js .vjs-big-play-button .vjs-icon-placeholder:after {
  content: '';
  height: 100px;
  width: 100px;
  border: solid 3px white;
  border-radius: 100%;
  z-index: 1;
}

.custom .video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  font-size: 4rem;
  box-shadow: none;
  text-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.audio-js {
  position: absolute;
  width: 100%;
  height: 100%;
}

.audio-js .vjs-control-bar {
  z-index: 2;
}

.audio-js .vjs-big-play-button {
  z-index: -1;
}

.vjs-audio.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
  pointer-events: all;
}

.vjs-theme-fantasy.vjs-has-started .vjs-big-play-button {
  display: none!important;
}
